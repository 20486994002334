.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.ta-menu-flags > button {
  height: 50px;
  border: none;
  outline: none;
}

.ta-menu-flags .ReactFlagsSelect-module_selectFlag__2q5gC {
  font-size: 1.5rem !important;
}

.otp-input > input {
  width: calc(100% / 6) !important;
  border: 1px solid #D9D9D9;
  height: 50px;
  outline: none;
  border-radius: 3px;
  user-select: none;
}

.otp-input > input::-webkit-outer-spin-button,
.otp-input > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.react-datepicker__input-container {
  height: 100%;
}

.react-datepicker__input-container > input {
  height: 100%;
  width: calc(100% + 20px);
  font-size: 0.875rem;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
